import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box, TextField } from '@mui/material';
import { _theme, SectionHead, SectionTitle, PinkButton } from './library';

export default function ContactUs() {
	const titleStyle = {
		color: 'white',
		textAlign: 'left',
		pb: 2,
		fontWeight: 400,
	};
	return (
		<>
			{/* <Grid2
				container
				direction='row'
				justifyContent='space-between'
				alignItems='flex-start'
				columnSpacing={{ sm: 0, md: 2 }}
				sx={{
					mt: 8,
					padding: _theme().spacing(6, 8),
					bgcolor: 'primary.dark',
					color: 'white',
					[_theme().breakpoints.down('sm')]: {
						p: _theme().spacing(4, 4),
					}
				}}>
				<Grid2 md={12}>
					<SectionTitle sx={titleStyle}>Contact Us</SectionTitle>
				</Grid2>
				<Grid2 md={4} sm={12} sx={{ pb: 2 }}>
					<SectionHead sx={{ textAlign: 'left', p: 0 }}>
						Have a question?
						<br />
						We’re here to help!
					</SectionHead>
				</Grid2>
				<Grid2 md={8} sm={12}>
					<Box
						sx={{
							bgcolor: 'white',
							boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
							borderRadius: 4,
							p: 2,
							pb: 7,
						}}>
						<TextField
							id='email'
							label='Email'
							type='email'
							variant='outlined'
							sx={{ width: '100%', '& div': { borderRadius: 3 } }}
						/>
						<TextField
							id='message'
							label='Message'
							variant='outlined'
							multiline
							rows={8}
							sx={{ width: '100%', mt: 3, mb: 2, '& div': { borderRadius: 3 } }}
						/>
						<PinkButton
							sx={{
								margin: 0,
								float: 'right',
							}}>
							Send
						</PinkButton>
					</Box>
				</Grid2>
			</Grid2> */}
			<Grid2
				container
				direction='row'
				justifyContent='space-between'
				alignItems='flex-start'
				columnSpacing={{ sm: 0, md: 2 }}
				sx={{
					padding: _theme().spacing(6, 8),
					bgcolor: 'primary.main',
					color: 'white',
					[_theme().breakpoints.down('sm')]: {
						p: _theme().spacing(4, 4),
					}

				}}>
				<Grid2 xs={12}>
					{/* <SectionTitle sx={{ ...titleStyle, textAlign: 'center', }}>Start your free demo</SectionTitle> */}
					<SectionHead sx={{ textAlign: 'center', p: 0, pb: 3 }}>
						Ready to level up your demand forecasting?
					</SectionHead>
					<PinkButton
						sx={{
							bgcolor: 'white',
							width: '50%',
							maxWidth: '400px',
							minWidth: '200px',
							color: 'primary.main',
							margin: '0 auto',
							float: 'none',
							textAlign: 'center',
							display: 'block',
							fontSize: '1rem',
						}}>
						Contact Us
					</PinkButton>
				</Grid2>
			</Grid2>
		</>
	);
}
