export const PAGES = {
	HOME: 'home',
	SIGNIN: 'signin',
	RUNS: 'runs',
	UPLOAD: 'upload',
	FILES: 'files',
};

export const FILESTAB = {
	RUNS: 0,
	UPLOAD: 1,
	PRE: 2,
	POST: 3,
	FILES: 4,
};

export const fullWidth = {
	width: '100%',
};

export const fullTop = {
	...fullWidth,
	left: 0,
	top: 0,
};

export const fullBottom = {
	...fullWidth,
	position: 'absolute',
	bottom: 0,
};

export const fullScreen = {
	...fullTop,
	position: 'absolute',
	bottom: 0,
};

export function rndInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function stringToColor(string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

export function avatarProps(name) {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
	};
}

// export function setCookie(cname, cvalue, exhours) {
// 	const d = new Date();
// 	d.setTime(d.getTime() + exhours * 60 * 60 * 1000);
// 	let expires = 'expires=' + d.toUTCString();
// 	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
// }
