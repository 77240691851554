import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { AppContext } from '../../App';
import { PAGES, FILESTAB, rndInt } from '../../Util';
import Header from './components/Header';
import UploadGuide from './components/UploadGuide';
import { theme } from '../../App';
import Upload from './components/Upload';
import Table from './components/Table';
import PreModel from './components/PreModel';
import PostModel from './components/PostModel';

import { getSingleRunAPI } from '../../api/API';

const filesApi = (run) => {
	let files = [];
	if (localStorage.getItem('rawDataFiles')) {
		files = JSON.parse(localStorage.getItem('rawDataFiles'));
	}
	let subFiles = [];
	if (files.length !== 0) {
		for (const f in files) {
			if (files[f].run === run.id) {
				subFiles.push(files[f]);
			};
		};
	};
	return subFiles
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return value === index ? (
		<Box role='tabpanel' sx={{ position: 'relative' }} {...other}>
			{children}
		</Box>
	) : null;
}

export default function Files() {
	const appContext = React.useContext(AppContext);
	const [tab, setTab] = React.useState(
		appContext.store.filesTab || FILESTAB.UPLOAD
	);
	const [subtab, setSubtab] = React.useState(appContext.store.filesSubtab || 0);
	const [showGuide, setShowGuide] = React.useState(
		!appContext.store.guideShown
	);

	React.useEffect(() => {
		if (tab === FILESTAB.RUNS) appContext.updateStore({ page: PAGES.RUNS });
		if (tab === FILESTAB.FILES) {
			setTab(FILESTAB.UPLOAD);
			setSubtab(FILESTAB.FILES);
		}
	}, [tab, appContext]);

	// update run and get status when tab changes
	React.useEffect(() => {
		getSingleRunAPI(appContext.store.run.id).then((res) => {
			// update local storage
			localStorage.setItem('currentSingleRun', JSON.stringify(res.data));	
			console.log('Get Single Run API called from Files.js');
			console.log(JSON.stringify(res.data));
			if (res.data.id !== appContext.store.run.id) {
				appContext.updateStore({ run: res.data });
			};
			if (res.data.status !== appContext.store.run.status) {
				appContext.updateStore({ run: res.data });
			};
		}).catch((error) => {
			console.error(error);
		});
	}, [appContext.filesTab]);

	const fileList = filesApi(appContext.store.run);

	return (
		<>
			<Header {...{ tab, setTab }} />
			<TabPanel value={tab} index={FILESTAB.RUNS}>
				Home
			</TabPanel>
			<TabPanel
				value={tab}
				index={FILESTAB.UPLOAD}
				sx={{ m: theme.spacing(3, 4) }}>
				{subtab !== FILESTAB.FILES && <Upload />}
				{subtab === FILESTAB.FILES && (
					<Table
						run={appContext.store.run}
						files={fileList}
					/>
				)}
			</TabPanel>
			<TabPanel value={tab} index={FILESTAB.PRE}>
				{(appContext.store.run.status === 'awaiting_data' || appContext.store.run.status === 'preprocessing') ?
					(<CircularProgress sx={{m: theme.spacing(3, 4)}} />)
					:(<PreModel 
						run={appContext.store.run}
					/>)
				}
			</TabPanel>
			<TabPanel value={tab} index={FILESTAB.POST}>
				{(appContext.store.run.status === 'premodel' || appContext.store.run.status === 'model_processing') ?
					(<CircularProgress sx={{m: theme.spacing(3, 4)}} />)
					:(<
						PostModel 
						run={appContext.store.run}
					/>)
				}
			</TabPanel>
			<UploadGuide
				open={!appContext.store.user.noGuide && showGuide}
				doClose={() => setShowGuide(false)}
			/>
		</>
	);
}
