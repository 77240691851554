import { Chip } from '@mui/material';
import CaretRight from '../assets/CaretRight.svg';

export default function ChipGo({ Icon, svg, bg, noGo, ...other }) {
	const GetIcon = () => {
		if (svg) return <img src={svg} width='10px' alt='' />;
		if (Icon) return <Icon sx={{ width: 12 }} />;
		return null;
	};

	return (
		<Chip
			icon={<GetIcon />}
			sx={{
				bgcolor: bg,
				height: 24,
				pl: 0.75,
				pr: 1.25,
				mr: 1,
				fontSize: '0.85rem',
				cursor: noGo ? '' : 'pointer',
				color: 'neutral.900',
				// '&::after': { content: "'▶'", fontSize: '0.75rem', color: 'neutral.700', },
				// '&::after': { content: `url(${CaretRight})`, fontSize: 0 },
				'&::after': noGo
					? {}
					: {
						content: "'➧'",
						fontSize: '1rem',
						color: 'neutral.700',
					},
			}}
			{...other}
		/>
	);
}
