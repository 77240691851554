import * as React from 'react';
import Table from './components/Table';
import Header from './components/Header';

import { AppContext } from '../../App';
import { getRunsAPI } from '../../api/API';

export default function Runs() {
	const appContext = React.useContext(AppContext);

	// // update run list
	// getRunsAPI().then((res) => {
	// 	if (appContext.store.currentRuns !== res.data) {
	// 		// update local storage
	// 		localStorage.setItem('currentRuns', JSON.stringify(res.data));
	// 		console.log('Get Runs API called from Runs.js')
	// 		appContext.updateStore({
	// 			currentRuns: res.data,
	// 		});
	// 	};
	// });
	// React.useEffect(() => {
	// 	if (appContext.store.currentRuns) {
	// 		getRunsAPI().then((res) => {
	// 			// update local storage
	// 			localStorage.setItem('currentRuns', JSON.stringify(res.data));
	// 			console.log('Get Runs API called from Runs.js')
	// 			// appContext.updateStore({
	// 			// 	currentRuns: res.data,
	// 			// });
	// 		});
	// 	};
	// }, [appContext.store.currentRuns]);

	return (
		<>
			<Header />
			<Table />
		</>
	);
}