import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

import { theme } from '../App';

export default function BLDialog({
	title,
	color = 'primary.main',
	content,
	actions,
	onClose,
	open,
	...rest
}) {
	return (
		<Dialog onClose={onClose} open={open} {...rest}>
			<DialogTitle
				variant='h5'
				color={color}
				sx={{ p: theme.spacing(4, 4, 3) }}>
				{title}
			</DialogTitle>
			<DialogContent sx={{ p: theme.spacing(3, 4) }}>{content}</DialogContent>
			{actions && <DialogActions sx={{ p: theme.spacing(3, 4) }}>{actions}</DialogActions>}
		</Dialog>
	);
}
