import * as React from 'react';
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';

import { theme } from '../../App';
import SignWindow from './components/SignWindow';
import bg from './assets/gradient.svg';
import logo from '../../assets/logo.svg';
import { fullScreen, fullBottom } from '../../Util';

export default function SignIn() {
	return (
		<>
			<Box sx={{
				...fullScreen,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',

				backgroundColor: 'primary.dark',
			}} >
				<Box sx={{
					...fullScreen,
					backgroundImage: `url(${bg})`,
					backgroundSize: 'cover',
					backgroundAttachment: 'fixed',
					mixBlendMode: 'hard-light'
				}} />
				<Box sx={{
					...fullScreen,
					backgroundImage: `url(${logo})`,
					backgroundSize: '70%',
					backgroundPosition: 'top right',
					backgroundRepeat: 'no-repeat',
					backgroundAttachment: 'fixed',
					opacity: 0.3,
					mixBlendMode: 'screen'
				}} />
			</Box>
			<Box sx={{
				...fullScreen,
			}} >
				<Box sx={{
					...fullScreen,
					overflow: 'auto',
				}} >
					<SignWindow />
				</Box>
				<Grid2 container sx={{
					...fullBottom,
					padding: theme.spacing(2, 4),
				}}>
					<Grid2 item xs={2}>
						<Button sx={{ color: 'white' }}>privacy policy</Button>
					</Grid2>
					<Grid2 item xs={2}>
						<Button sx={{ color: 'white' }}>terms of use</Button>

					</Grid2>
					<Grid2 item xs={8} sx={{
						textAlign: 'right',
						fontSize: '0.875rem',
						color: 'white',
						lineHeight: 2.5,
					}}>
						©BetterLogiq Inc. All Rights Reserved.
					</Grid2>
				</Grid2>
			</Box>
		</>
	)
};