import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { swish, SectionTitle, SectionHead, PinkButton } from './library';
import WarehouseSuits from '../assets/WarehouseSuits.jpg';
import Founder from '../assets/Founder.jpg';

export default function Why() {
	const Item = ({ title, children }) => {
		const _title = title.substr(0, title.length - 1);
		const _last = title.substr(-1, 1);
		return (
			<Grid2 xs={6}>
				<Typography variant='h4' sx={{ span: { color: 'primary.main' } }}>
					{_title}
					<span>{_last}</span>
				</Typography>
				<Typography>{children}</Typography>
			</Grid2>
		);
	};
	return (
		<Grid2
			container
			direction='row'
			justifyContent='space-between'
			alignItems='flex-start'
			columnSpacing={{ xs: 0, sm: 5, md: 10 }}
			sx={{ pt: 8, maxWidth: 'calc(100% + 60px)' }}>
			<Grid2 
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
				xs={12} 
				sm={6}
				>
				<img width='50%' src={WarehouseSuits}></img>
			</Grid2>
			<Grid2 xs={12} sm={6} sx={{ p: { pt: 1} }}>
				<SectionTitle>why betterlogiq?</SectionTitle>
				<SectionHead>
					Not an expert at model-agnostic meta-learning?{' '}
					<span style={{ ...swish(100) }}>Not&nbsp;a&nbsp;problem</span>
				</SectionHead>
				{/* <Typography variant='body1' gutterBottom sx={{pt: 2}}>
					Our solution is built for non-data scientists.
				</Typography> */}
				<Typography variant='body1' gutterBottom sx={{pt: 2}}>
					We’re academics at heart. But we’ve also spent years consulting
					with some of the largest global corporations on their most complex
					supply chain problems. We took those learnings and turned them into
					BetterLogiq. Sophisticated solution? Yes. Easy to implement? Most definitely.
				</Typography>
				<Grid2
					container
					direction='row'
					justifyContent='flex-start'
					alignItems='flex-start'
					spacing={2}
					columnSpacing={{ xs: 3, md: 5 }}
					sx={{ p: 0, pt: 1 }}>
					<Item title='30%'>Typical forecast improvement</Item>
					<Item title='20M+'>Product-months forecasted</Item>
					<Item title='100s - 50,000+'>Typical SKUs / forecast</Item>
					<Item title='95%'>Typical Run-time Improvement</Item>
				</Grid2>
				<Grid2
					xs={12}
					sx={{
						p: 0,
						pt: 1,
						hr: { color: 'neutral.50', mb: 2 },
						img: { mb: 2, mr: 2, float: 'left' },
					}}>
					<hr />
					{/* <img src={Founder} alt='Founder' />
					<Typography variant='h6'>
						"BetterLogiq is for companies that want to improve their inventory
						controls."
					</Typography>
					<Typography sx={{ fontWeight: 700 }}>
						Co-Founder & CTO, BetterLogiq
					</Typography> */}
					<PinkButton
						sx={{
							margin: '30px auto',
							width: '200px',
							float: 'none',
							textAlign: 'center',
							display: 'block',
						}}>
						Contact Us
					</PinkButton>
				</Grid2>
			</Grid2>
		</Grid2>
	);
}
