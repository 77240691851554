import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/material';
import logo from '../../../assets/logo.svg';
import logo_text from '../../../assets/logo_text.svg';

export default function Footer() {
	return (
		<Grid2
			container
			direction='row'
			justifyContent='center'
			alignItems='center'
			sx={{
				padding: 4,
				fontSize: '1rem',
			}}>
			<Grid2
				xs={10}
				sx={{
					p: 6,
					textAlign: 'center',
					borderBottom: 1,
					borderColor: 'primary.dark',
				}}>
				<img
					src={logo}
					alt='Betterlogiq logo'
					width={40}
					style={{ marginRight: 10 }}
				/>
				<img
					src={logo_text}
					alt='Betterlogiq logo'
					width={180}
					style={{ verticalAlign: 'top' }}
				/>
				<Box sx={{ textAlign: 'center', color: 'primary.dark' }}>
					Better Forecasts. Better Decisions. Better Outcomes.
				</Box>
			</Grid2>
			<Grid2 xs={10} sx={{ p: 2 }}>
				<Box sx={{ textAlign: 'center', color: 'primary.dark' }}>
					COPYRIGHT © 2024. BetterLogiq Inc. All rights reserved.
				</Box>
			</Grid2>
		</Grid2>
	);
}
