import * as React from 'react';
import BLDialog from './BLDialog';
import { BLDataGrid } from './BLDataGrid';
import data from '../assets/raw_data';
import { Button } from '@mui/material';

const dataColumns = [
	{
		field: 'i',
		headerName: '',
		headerClassName: 'idx',
		width: 30,
		filterable: false,
		sortable: false,
		disableColumnMenu: true,
		disableReorder: true,
		renderCell: (data) => data.api.getRowIndex(data.row.id) + 1,
	},
	{
		field: 'id',
		headerName: 'Unique ID',
		width: 300,
		hideable: false,
	},
	{
		field: 'date',
		headerName: 'Date',
		width: 100,
		hideable: false,
	},
	{
		field: 'demand',
		headerName: 'Demand',
		width: 100,
		hideable: false,
	},
];

export default function DataRaw({ open, setOpen }) {
	return (
		<BLDialog
			open={open}
			onClose={() => setOpen(false)}
			title={`Raw Data Sample Data Format`}
			content={
				<BLDataGrid
					rows={data}
					columns={dataColumns}
					pageSize={50}
					density='compact'
					rowHeight={58}
					disableSelectionOnClick
					autoHeight
				/>
			}
			actions={
				<>
					<Button variant='outlined' onClick={() => setOpen(false)}>
						Close
					</Button>
				</>
			}
			fullWidth
			maxWidth='md'
		/>
	);
}
