import * as React from 'react';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import IconButton from '@mui/material/IconButton';

import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { AppContext } from '../../../App';
import { PAGES } from '../../../Util';
import { getProjects } from '../../../api/API';


const SignPass = () => {
  const { loginWithRedirect, isLoading } = useAuth0();
  const { appContext } = React.useContext(AppContext);
  localStorage.setItem('appContext', JSON.stringify(appContext));

  const handleLogin = async () => {
    try {
      localStorage.clear();
      await loginWithRedirect({
        // TODO: possibly handle redirect here instead of in App.js useEffect
      });
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <Button variant="contained" color="primary" onClick={handleLogin} sx={{
      margin: '30px auto',
      width: '200px',
      float: 'none',
      textAlign: 'center',
      display: 'block',
    }}>
      Login
    </Button>
  );
};

export default SignPass;