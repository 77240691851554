import { styled } from '@mui/material/styles';
import { DataGrid } from "@mui/x-data-grid";

export const BLDataGrid = styled(DataGrid)(({ theme }) => ({
	'.MuiDataGrid-row:nth-of-type(even), .MuiDataGrid-cell:nth-of-type(1)': {
		backgroundColor: theme.palette.neutral[100],
		'& .MuiDataGrid-cell:nth-of-type(1)': {
			backgroundColor: theme.palette.neutral[300],
		},
	},
	'.idx': {
		backgroundColor: theme.palette.neutral[300],
	},
	'.runName': {
		cursor: 'pointer',
	},
}));