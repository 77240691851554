import * as React from 'react';
import { createPortal } from 'react-dom';
import { Box, Button, Paper, Typography, CircularProgress } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ChartBox from '../../../components/chart/ChartBox';
import TimeSeries from '../../../components/chart/TimeSeries';
import Category from '../../../components/chart/Category';
import { theme } from '../../../App';
import { AppContext } from '../../../App';
import { FILESTAB } from '../../../Util';
import { timeData, timeDataM, categoryData } from '../../../assets/chart_data';

import { getPostModelVisData } from '../../../api/API';

export default function PostModel({ run }) {
	const appContext = React.useContext(AppContext);
	const [btnSlot, setBtnSlot] = React.useState(null);
	const [loadingData, setLoadingData] = React.useState(true);

	const SummaryItem = ({ title, data }) => (
		<Grid2 xs={3}>
			<Box
				borderTop={`4px solid ${theme.palette.primary.light}`}
				bgcolor='neutral.900'
				p={4}>
				<Typography variant='overline' color='white'>
					{title}
				</Typography>
				<Typography variant='h3' color={'primary.light'}>
					{data}
				</Typography>
			</Box>
		</Grid2>
	);

	React.useEffect(() => {
		setBtnSlot(document.getElementById('process-btns'));
	}, []);

	// get the model vis data when this first loads
	React.useEffect(() => {
		getPostModelVisData(run.id).then((res) => {
			localStorage.setItem('postVisData', JSON.stringify(res.data));
		}).then(() => {
			setLoadingData(false);
		}).catch((error) => console.log(error.response));
	}, []);

	let timeDataNew = [
		{
		date: '2022-01',
		categories: ['Product 1', 'Product 2'],
		values: [4000, 3200]
		}
	];

	let tileData = {
			first_date: 'unknown',
			last_date: 'unknown',
			product_count: 'unknown',
			average_demand: 'unknown'
	};
	
	if (loadingData !== true) {
		timeDataNew = JSON.parse(localStorage.getItem('postVisData')).time_series_demand;
		tileData =  JSON.parse(localStorage.getItem('postVisData')).stats_data;
	};

	return (
		<>
			{loadingData === true ?
				(<CircularProgress />)
				:(<Grid2 container>
					{/* settings */}
					{/* <Grid2
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='stretch'
						xs={'auto'}>
						<Grid2>
							<Box sx={{ p: theme.spacing(1, 3), bgcolor: 'neutral.100' }}>
								<Typography>
									Saved views <strong>Default</strong>
								</Typography>
							</Box>
							<Paper sx={{ p: 3, height: '100%', zIndex: 100 }}>Filters</Paper>
						</Grid2>
					</Grid2> */}
					{/* charts */}
					<Grid2
						container
						direction='column'
						justifyContent='flex-start'
						alignItems='flex-start'
						bgcolor='neutral.50'
						xs>
						<Grid2 xs={12}>
							<Box
								sx={{
									p: theme.spacing(1, 3),
									bgcolor: 'neutral.100',
									width: '100%',
								}}>
								{/* <Typography color='neutral.500' textAlign='right'>
									auto-saved 30s ago
								</Typography> */}
							</Box>
						</Grid2>
						<Grid2 container spacing={0.125} xs={12}>
							<SummaryItem title='demand start' data={tileData.first_date} />
							<SummaryItem title='demand finish' data={tileData.last_date} />
							<SummaryItem title='count of products' data={Number(tileData.product_count).toLocaleString("en-US")} />
							<SummaryItem title='average demand' data={Number(tileData.average_demand).toLocaleString("en-US")} />
						</Grid2>
						<Grid2 container xs={12} p={4} spacing={8}>
							<Grid2 xs={12}>
								<ChartBox
									title='Historical'
									ChartType={TimeSeries}
									settings={{ label: 'Demand', view: 'Line' }}
									data={timeDataNew}
								/>
							</Grid2>
							{/* <Grid2 xs={6}>
								<ChartBox
									title='Product demand'
									ChartType={Category}
									settings={{ label: 'Products' }}
									data={categoryData}
								/>
							</Grid2>
							<Grid2 xs={6}>
								<ChartBox
									title='Historical'
									ChartType={TimeSeries}
									settings={{ label: 'Demand', view: 'Stacked' }}
									data={timeDataM}
								/>
							</Grid2> */}
						</Grid2>
					</Grid2>
				</Grid2>)
			}
			{btnSlot &&
				createPortal(
					<>
						<Button variant='outlined' sx={{ mr: 2 }}>
							export
						</Button>
					</>,
					btnSlot
				)}
		</>
	);
}
