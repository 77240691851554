import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';

import logo_text from '../../../assets/logo_text.svg';
import { fullTop } from '../../../Util';
import { AppContext, theme } from '../../../App';
import { useAuth0 } from "@auth0/auth0-react";

export default function Header() {
	const appContext = React.useContext(AppContext);
	const { logout } = useAuth0();
	
	const handleLogout = () => {
		localStorage.clear();
		logout({ logoutParams: { returnTo: window.location.origin } });
	}
	
	// adding appContext to local storage for dev and debugging
	localStorage.setItem('appContext', JSON.stringify(appContext));
	
	return (
		<Grid2
			container
			direction='row'
			justifyContent='space-between'
			alignItems='stretch'
			sx={{
				...fullTop,
				padding: theme.spacing(2, 4),
				bgcolor: 'neutral.100',
				borderBottom: 2,
				borderColor: 'neutral.300',
			}}>
			<Grid2 item xs={4} sx={{ pt: 1 }}>
				<img
					src={logo_text}
					alt='Betterlogiq logo'
					width={180}
				/>
			</Grid2>
			<Grid2 item xs={5} sx={{ textAlign: 'right', pt: 1 }}>
				<NotificationsOutlined sx={{ m: theme.spacing(1, 2), fontSize: '2rem' }} />
				<Avatar
					alt={appContext.store.user.name}
					src={appContext.store.user.avatar}
					sx={{
						// width: 40,
						// height: 40,
						display: 'inline-flex',
					}}></Avatar>
				<Button variant="contained" sx={{display:'inline-flex', ml:2, mb:2 }} onClick={() => handleLogout()}>
					Logout
				</Button>
			</Grid2>
		</Grid2>
	);
}
