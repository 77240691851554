import * as React from 'react';
import { createPortal } from 'react-dom';
import Button from '@mui/material/Button';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import ErrorOutlineOutlined from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined';
import { AppContext } from '../../../App';
import { BLDataGrid } from '../../../components/BLDataGrid';
import { FILESTAB } from '../../../Util';
import ChipGo from '../../../components/ChipGo';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import axios from "axios";

import { getRunsAPI, getSingleRunAPI } from '../../../api/API';

const getErrorChip = (data) => {
	if (data.errors) {
		let label = `${data.errors} errors`;
		if (data.warnings) label = label + `  ${data.warnings} warnings`;
		return (
			<ChipGo Icon={ErrorOutlineOutlined} bg='error.light' label={label} />
		);
	} else if (data.warnings) {
		return (
			<ChipGo
				Icon={ErrorOutlineOutlined}
				bg='warning.light'
				label={`${data.warnings} warnings`}
			/>
		);
	} else
		return (
			<ChipGo
				Icon={CheckCircleOutlined}
				bg='success.light'
				label={'0 errors'}
				noGo
			/>
		);
};

export default function Table({ run, files }) {
	const appContext = React.useContext(AppContext);
	const [deleteFile, setDeleteFile] = React.useState(null);
	const [btnSlot, setBtnSlot] = React.useState(null);
	const [errorNum, setErrorNum] = React.useState(0);

	const fileColumns = [
		{
			field: 'i',
			headerName: '',
			headerClassName: 'idx',
			width: 30,
			filterable: false,
			sortable: false,
			disableColumnMenu: true,
			disableReorder: true,
			renderCell: (data) => data.api.getRowIndex(data.row.id) + 1,
		},
		{
			field: 'name',
			headerName: 'File',
			width: 400,
			editable: true,
			hideable: false,
		},
		{
			field: 'data_type',
			headerName: 'Type',
			width: 100,
		},
		{
			field: 'errors',
			headerName: 'Errors',
			width: 200,
			renderCell: (data) => getErrorChip(data.row),
		},
		{
			field: 'delete',
			headerName: '',
			width: 40,
			filterable: false,
			sortable: false,
			disableColumnMenu: true,
			disableReorder: true,
			hideable: false,
			hide: run.preProcessingEst,
			renderCell: (data) => (
				<Button
					title={`Delete ${data.row.name}`}
					onClick={() =>
						setDeleteFile({ id: data.row.id, name: data.row.name })
					}
					sx={{ minWidth: 0, color: 'error.main' }}>
					<DeleteOutlined />
				</Button>
			),
		},
		{
			field: 'created',
			headerName: 'Created on',
			width: 150,
			renderCell: (data) => data.row.created.substring(0, 10),
		},
		{
			field: 'Actions',
			width: 140,
			hide: run.preProcessingEst,
			renderCell: (data) =>
				!!(data.row.errors || data.row.warnings) && (
					<Button variant='outlined' onClick={handleUpload}>
						<FileUploadOutlined sx={{ mr: 1 }} />
						Replace
					</Button>
				),
		},
		{
			field: 'description',
			headerName: 'Description',
			width: 200,
			hide: true,
		},
		{
			field: 'tags',
			headerName: 'Tags',
			width: 150,
			hide: true,
		},
	];

	const handleRename = () => { };

	const handleUpload = () =>
		appContext.updateStore({
			filesSubtab: FILESTAB.UPLOAD
		 });
	
	const preprocessStart = () => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem('access')}`
			}
		};
		const body = {};
		const instance = axios.create();
		return instance.post(
			`/run/${run.id}/preprocess/`,
			body,
			config
		);
	};
	const handlePreprocess = (run) => {
		preprocessStart().then((res1) => { // added res1 to wait for preprocess api to start and respond
			getRunsAPI().then((res) => {
				// update local storage
				localStorage.setItem('currentRuns', JSON.stringify(res.data));
			}).catch((except => console.error(except)));
			appContext.updateStore({
				filesTab: FILESTAB.PRE,
			});
		}).catch((except => console.error(except)));
	};

	React.useEffect(() => {
		setErrorNum(files.reduce((total, file) => total + file.errors, 0));
		setBtnSlot(document.getElementById('process-btns'));
	}, [files]);

	// update run on component render, only kinda works as we want it
	React.useEffect(() => {
		getSingleRunAPI(run.id).then((res) => {
			console.log(JSON.stringify(res.data));
			if (appContext.store.run.id != res.data.id || appContext.store.run.status != res.data.status) {
				// update local storage
				localStorage.setItem('run', JSON.stringify(res.data));
				console.log('Get Runs API called from Runs.js')
				appContext.updateStore({
					run: res.data,
				});
			};
		});
	}, []);

	return (
		<>
			<Grid2 container>
				<Grid2 xs={8}>
					{!!errorNum && (
						<span>
							There were some issues with your uploaded files. Please resolve{' '}
							{errorNum} errors to continue.
						</span>
					)}
				</Grid2>
				<Grid2 xs={4} sx={{ textAlign: 'right' }}>
					{!run.preProcessingEst && (
						<Button variant='contained' onClick={handleUpload} sx={{ mb: 2 }}>
							<FileUploadOutlined sx={{ mr: 1 }} /> Upload More Data
						</Button>
					)}
				</Grid2>
			</Grid2>
			<BLDataGrid
				rows={files}
				columns={fileColumns}
				pageSize={10}
				rowsPerPageOptions={[10]}
				density='compact'
				rowHeight={58}
				onCellEditCommit={handleRename}
				disableSelectionOnClick
				autoHeight
			/>
			{btnSlot && (run.status === 'awaiting_data') &&
				createPortal(
					<Button variant='contained' onClick={handlePreprocess} disabled={!!errorNum}>
						pre-process data
					</Button>,
					btnSlot
				)}
		</>
	);
}
