import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { theme } from '../../App';

export default function ChartBox({ title, Options, ChartType, settings, data }) {
	const [chartSet, setChartSet] = React.useState(settings);
	return (
		<Paper sx={{ borderTop: `2px solid ${theme.palette.chart[1]}` }}>
			<Grid2 container p={4} spacing={0}>
				<Grid2 xs={6}>
					<Typography variant='h6' color='primary'>
						{title}
					</Typography>
				</Grid2>
				<Grid2 xs={6}>{Options && <Options state={[chartSet, setChartSet]} />}</Grid2>
				<Grid2 xs={12} pt={3} height={settings.height || 350}>
					<ChartType {...{ settings: chartSet, data }} />
				</Grid2>
			</Grid2>
		</Paper>
	);
}
