import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import { swish, SectionTitle, SectionHead, _theme } from './library';

export default function Questions() {
	const styleSummary = { '& p': { maxWidth: '85%', fontWeight: 600, minHeight: '3rem' } };

	return (
		<Grid2
			container
			direction='row'
			justifyContent='space-between'
			alignItems='flex-start'
			columnSpacing={{ xs: 0, sm: 5 }}
			rowSpacing={{ xs: 3 }}
			sx={{ pt: 4 , pb: 4}}>
			<Grid2 xs={12} sx={{ pb: 1 }}>
				<SectionTitle>Common Questions</SectionTitle>
				<SectionHead>
					Frequently Asked <span style={{ ...swish(88) }}>Questions</span>
				</SectionHead>
			</Grid2>

			<Grid2 xs={12} sm={6}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='question-1'
						id='question-1'
						sx={styleSummary}>
						<Typography>
							What makes BetterLogiq different from other demand forecasting
							tools?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							We use a proprietary machine learning algorithm and multi-step process that 
							utilizes state-of-the-art ML/AI models coupled with our teams' decades of forecasting 
							experience to generate product-level forecasts at scale. We continuously innovate and 
							integrate the leading edge technology into our application and into your forecasts.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Grid2>
			<Grid2 xs={12} sm={6}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='question-2'
						id='question-2'
						sx={styleSummary}>
						<Typography>
							How does BetterLogiq protect my company’s data?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							BetterLogiq follows best practices for data security with isolated data storage and 
							strict policies build from SOC 2 guidlines. BetterLogiq also offers the option for 
							full isolation with single-tenant deployment. 
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Grid2>
			<Grid2 xs={12} sm={6}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='question-3'
						id='question-3'
						sx={styleSummary}>
						<Typography>
							What kind of data can I upload?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							At a minimum, historical demand/sales data at the same time granularity to be forecast. 
							You can also add categorical data like product category, geography, and warehouse location. 
							Product-specific data such as promotional periods can also be uploaded.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Grid2>
			<Grid2 xs={12} sm={6}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='question-4'
						id='question-4'
						sx={styleSummary}>
						<Typography>
							How does your pricing work?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							Our pricing depends on a number of factors including the number of products, the time granularity, 
							the forecast horizon, and more. Our customer care team can walk through your specific use case.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Grid2>
			<Grid2 xs={12} sm={6}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='question-5'
						id='question-5'
						sx={styleSummary}>
						<Typography>
							What time granularity can you forecast?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							Many clients find monthly data to be granular enough for planning, however, 
							we can generate forecasts at the weekly or daily level too, provided you have 
							historical data at the same granularity.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Grid2>
			<Grid2 xs={12} sm={6}>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='question-6'
						id='question-6'
						sx={styleSummary}>
						<Typography>
							What is the typical improvement in forecast accuracy?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							Some customers have seen more than a 50% increase in forecast accuracy across their universe of products. 
							Typically, we find significant improvement both in aggregate and at the product-level over competing tools.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Grid2>

		</Grid2>
	);
}
