import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	Link,
	Typography,
} from '@mui/material';
import * as React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';

import { AppContext } from '../../../App';
import { FILESTAB } from '../../../Util';

import FileNumIcon from '../../../components/FileNumIcon';
import FileCircle from '../../../assets/FileCircle.svg';
import DataRaw from '../../../components/DataRaw';
import DataStatic from '../../../components/DataStatic';

import { directUploadStart, directUploadDo, directUploadFinish, getRawFilesAPI } from '../../../api/API';

const fileTypes = ['CSV', 'PARQUET', 'SVG'];

export function DragAndDrop({ updateFiles }) {
	const handleChange = (file) => {
		updateFiles(file);
	};

	return (
		<FileUploader
			multiple
			handleChange={handleChange}
			name='file'
			types={fileTypes}>
			<Box
				sx={{
					border: '2px dashed blue',
					borderColor: 'primary.main',
					borderRadius: 4,
					p: 4,
					mb: 2,
				}}>
				<Typography variant='h6' color='primary' pb={2}>
					Drag and drop files here.
				</Typography>
				<Typography pb={3} color='neutral.700'>
					You can upload multiple files for this data. Make sure your files are
					in the correct format.
				</Typography>
				<Button variant='outlined'>Browse computer</Button>
			</Box>
		</FileUploader>
	);
}

const File = ({ info, handleDelete }) => {
	const size = (s) => {
		return `${Math.ceil(s / 1024)}KB`;
	};

	return (
		<Grid2 spacing={1} container pt={0}>
			<Grid2 xs={'auto'}>
				<img src={FileCircle} height={40} alt='' />
			</Grid2>
			<Grid2 container xs sx={{ p: 0.5 }}>
				<Grid2 xs={11}>
					<Typography color={'primary'} fontWeight={700}>
						{info.name}
					</Typography>
					<Typography fontSize={'0.875rem'} color='neutral.700'>
						Size: {size(info.size)}
					</Typography>
				</Grid2>
				<Grid2 xs={1} textAlign='right'>
					{!info.uploading && (
						<IconButton onClick={() => handleDelete(info.idx)}>
							<DeleteOutlined />
						</IconButton>
					)}
				</Grid2>
				{info.uploading && (
					<Grid2 xs={12}>
						<LinearProgress color='primary' />
					</Grid2>
				)}
			</Grid2>
		</Grid2>
	);
};

export function UploadPanel({ title, fileNum, sample }) {
	const appContext = React.useContext(AppContext);
	const [files, setFiles] = React.useState([]);
	const [uploading, setUploading] = React.useState(false);

	const handleDelete = (idx) => setFiles(files.filter((f) => f.idx !== idx));

	const updateFiles = (list) => {
		const ff = [...files];
		for (const f in list) {
			if (Object.hasOwnProperty.call(list, f)) {
				list[f].idx = ff.length;
				list[f].uploading = uploading;
				ff.push(list[f]);
			}
		}
		setFiles(ff);
		console.log(ff);
	};

	const handleUpload = (e) => {
		setUploading(true);
		const ff = [...files];
		let dtType = '';
		for (const f in ff) {
			if (Object.hasOwnProperty.call(ff, f)) {
				ff[f].uploading = uploading;
				// upload the file
				if (fileNum === 1) dtType = 'time_series';
				if (fileNum === 2) dtType = 'static';
				directUploadStart( JSON.stringify(appContext.store.run.id), ff[f].name, dtType ).then((response) => 
						directUploadDo( response.data, ff[f] ))
						.then((res2) => { // need the dummy res2 to ensure we wait for the upload to complete before sending the finish api call
							directUploadFinish()
						})
							.then(() => {
								ff[f].uploading = false
							}).then(() => getRawFilesAPI())
							.then((res) => {
								localStorage.setItem('rawDataFiles', JSON.stringify(res.data))
								if (res.data != []) {
									appContext.updateStore({
										// filesTab: FILESTAB.FILES
										filesTab: FILESTAB.UPLOAD,
										filesSubtab: FILESTAB.FILES
									});
								} else {
									appContext.updateStore({
										filesTab: FILESTAB.UPLOAD,
										filesSubtab: FILESTAB.FILES
									});
								};
							});
			}
		}
		setFiles(ff);
	}

	const handleSample = (e) => {
		sample.set(true);
		e.stopPropagation();
		e.preventDefault();
	};

	return (
		<Box sx={{ bgcolor: 'neutral.100', borderRadius: 4, p: 6 }}>
			<Box p={1} pb={4}>
				<FileNumIcon number={fileNum} />
				<Typography
					variant='h4'
					color={'primary'}
					sx={{ display: 'inline-block', verticalAlign: 'top' }}>
					{title}
					<Link
						id='view-link'
						onClick={handleSample}
						sx={{ fontSize: '1rem', fontWeight: 400, display: 'block' }}>
						View sample data file.
					</Link>
				</Typography>
			</Box>
			<DragAndDrop {...{ updateFiles }} />
			<Box>
				{files.map((f, i) => (
					<File info={f} key={i} {...{ handleDelete }} />
				))}
			</Box>
			<Box sx={{ textAlign: 'right' }}>
				<Button
					variant='contained'
					disabled={files.length === 0}
					onClick={handleUpload}>
					Start Upload
				</Button>
			</Box>
		</Box>
	);
}

export default function Upload() {
	const [showRaw, setShowRaw] = React.useState(false);
	const [showStatic, setShowStatic] = React.useState(false);
	return (
		<>
			<Grid2
				container
				spacing={4}
				justifyContent='space-between'
				alignItems='flex-start'>
				<Grid2 xs={6}>
					<UploadPanel
						title={'Add raw data files.'}
						fileNum={1}
						sample={{ show: showRaw, set: setShowRaw }}
					/>
				</Grid2>
				<Grid2 xs={6}>
					<UploadPanel
						title={'Add static (time invariant) data.'}
						fileNum={2}
						sample={{ show: showStatic, set: setShowStatic }}
					/>
				</Grid2>
			</Grid2>
			<DataRaw open={showRaw} setOpen={setShowRaw} />
			<DataStatic open={showStatic} setOpen={setShowStatic} />
		</>
	);
}
