import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignIn from './pages/SignIn/SignIn';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import './App.css';
import { PAGES, FILESTAB, fullScreen } from './Util';
import Runs from './pages/Runs/Runs';
import Files from './pages/Files/Files';
import Home from './pages/Home/Home';
import { checkAuthentication } from './api/API';
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { getProjects } from './api/API';
let avatar = require('./assets/Avatar.png');

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0b78d2',
      light: '#77b7ec',
      dark: '#03366b',
    },
    secondary: {
      main: '#802a2a',
    },
    text: {
      primary: '#151D23',
      secondary: '#5B6267',
      white: '#fff',
    },
    error: {
      main: '#cd2534',
      dark: '#802a2a',
      light: '#eccfcf',
    },
    warning: {
      main: '#8c6d1e',
      light: '#F4EDD2',
    },
    success: {
      main: '#4d7a14',
      light: '#D7EEC5',
    },
    neutral: {
      50: '#FBFBFB',
      100: '#F1F4F6',
      300: '#E0E4E9',
      500: '#9BA7B2',
      700: '#5B6267',
      900: '#252829',
      black: '#151D23',
    },
    chart: [
      '#0B78D2',
      '#77B7EC',
      '#071A2F',
      '#C188EF',
      '#5D1B92',
      '#9BA7B2',
      '#5B6267',
      '#E0E4E9',
      '#03366b',
      '#eccfcf',
    ],
  },
  typography: {
    // htmlFontSize: 14,
    fontFamily:
      "'Inter', -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    h1: {
      fontWeight: 600,
      fontSize: '4.57rem',
      fontFamily: "'Sofia Sans', 'Inter', 'Helvetica Neue', sans-serif",
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
      fontFamily: "'Sofia Sans', 'Inter', 'Helvetica Neue', sans-serif",
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.29rem',
      fontFamily: "'Sofia Sans', 'Inter', 'Helvetica Neue', sans-serif",
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.72rem',
      fontFamily: "'Sofia Sans', 'Inter', 'Helvetica Neue', sans-serif",
    },
    h5: {
      fontSize: '1.5rem',
    },
    overline: {
      letterSpacing: 1.25,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          borderRadius: 40,
          fontWeight: 700,
          letterSpacing: 1.25,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          letterSpacing: 0.5,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 40,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: 8,
          marginRight: 32,
          minWidth: 50,
          minHeight: 40,
        },
      },
    },
  },
});

theme.components = {
  ...theme.components,
  MuiDataGrid: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
      },
      columnHeaders: {
        backgroundColor: theme.palette.neutral[100],
        borderTop: `4px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.neutral[500]}`,
      },
      columnHeaderTitle: {
        color: theme.palette.primary.main,
        letterSpacing: '1.25px',
        fontWeight: 700,
        textTransform: 'uppercase',
      },
    },
  },
};

export const AppContext = React.createContext(null);

function App() {
  // get the host domain
  const host = window.location.host;
  const [store, setStore] = React.useState(false);
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    error,
    user,
    logout,
  } = useAuth0();
  
  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      localStorage.setItem("access", token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  React.useEffect(() => {
    if (host.includes('app.')) {
      console.log('reachedhostloop', true);
      console.log('AppContext', JSON.stringify(store));
      console.log('isAuthenticated', isAuthenticated);
      if (isAuthenticated) {
        console.log('user', user);
        if (store.page === PAGES.SIGNIN) {
          (async () => {
            try {
              const token = await getAccessTokenSilently();
              console.log('get silent token in App.js useEffect', token);
              localStorage.setItem("access", token);
              axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
              getProjects().then((results) => {
                localStorage.setItem('projectList', JSON.stringify(results.data))
                console.log('getProjects called from handleNewProject in App.js')
                setStore({
                  page: PAGES.RUNS,
                  avatar: avatar,
                  user: user,
                  projectList: results.data,
                });
              }).catch((excep) => console.error(excep));
            } catch (e) {
              // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
              console.error(e);
            }
          })();
        }
      } else {
        setStore({
          page: PAGES.SIGNIN,
          avatar: avatar,
        });
      };
    } else {
      setStore({
        page: PAGES.HOME,
      });
    };
  }, [isAuthenticated]);

  if (isLoading) {
    console.log('loading', isLoading);
    return <Box sx={{
      ...fullScreen,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      backgroundColor: 'primary.dark',
    }} ><CircularProgress /></Box>;
  } 
  if (error) {
    console.log('error', error);
    return <div>Oops... {error.message}</div>;
  }

  const updateStore = ({ ...updates }) => setStore({ ...store, ...updates });

  const AppPages = () => {
    switch (store.page) {
      case PAGES.HOME:
        return <Home />;
      case PAGES.SIGNIN:
        return <SignIn />;
      case PAGES.RUNS:
        return <Runs />;
      case PAGES.FILES:
        return <Files />;
      default:
        return <Home />;
    }
  };
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContext.Provider value={{ store, setStore, updateStore }}>
        <AppPages />
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default App;
