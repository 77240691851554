import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Checkbox, FormControlLabel } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import { AppContext } from '../../../App';
import BLDialog from '../../../components/BLDialog';
import FileNumIcon from '../../../components/FileNumIcon';
import DataRaw from '../../../components/DataRaw';
import DataStatic from '../../../components/DataStatic';


export default function UploadGuide({ open, doClose }) {
	const appContext = React.useContext(AppContext);
	const [noGuide, setNoGuide] = React.useState(appContext.store.user.noGuide || false);
	const [showRaw, setShowRaw] = React.useState(false);
	const [showStatic, setShowStatic] = React.useState(false);


	const handleCheck = (e) => {
		setNoGuide(e.target.value === 'on');
	};

	const handleUpload = () => {
		const user = { ...appContext.store.user, noGuide };
		appContext.updateStore({ user: { ...user }, guideShown: true });
		doClose();
	}

	return (
		<BLDialog
			open={!!open}
			onClose={doClose}
			title='How to upload data'
			content={
				<>
					<Typography variant='h6' gutterBottom fontWeight={700}>
						You will need to import two sets of data.
					</Typography>
					<Typography variant='h6' mb={4}>
						At a minimum, they must include: unique IDs, dates, and historical
						demand data. We accept CSV or Parquet files.
					</Typography>
					<Grid2 container p={1}>
						<Grid2 xs={8} pb={4}>
							<FileNumIcon number={1} sx={{ float: 'left', mr: 4 }} />
							<Typography variant='overline' fontSize='.875rem' lineHeight={0} fontWeight={700}>Raw data</Typography>
							<Typography>This file type shows a</Typography>
						</Grid2>
						<Grid2 xs={4} textAlign='right'>
							<Button variant='outlined' size='small' onClick={() => setShowRaw(true)} ><VisibilityOutlined sx={{ mr: 0.5, fontSize: '1.25rem' }} /> view sample</Button>
						</Grid2>
						<Grid2 xs={8}>
							<FileNumIcon number={2} sx={{ float: 'left', mr: 4 }} />
							<Typography variant='overline' fontSize='.875rem' lineHeight={0} fontWeight={700}>Static (time invariant) data</Typography>
							<Typography>This file type shows a</Typography>
						</Grid2>
						<Grid2 xs={4} textAlign='right'>
							<Button variant='outlined' size='small' onClick={() => setShowStatic(true)}><VisibilityOutlined sx={{ mr: 0.5, fontSize: '1.25rem' }} /> view sample</Button>
						</Grid2>
					</Grid2>
					<DataRaw open={showRaw} setOpen={setShowRaw} />
					<DataStatic open={showStatic} setOpen={setShowStatic} />
				</>
			}
			actions={
				<>
					<FormControlLabel
						control={<Checkbox onChange={handleCheck} checked={noGuide} />}
						label='Don’t show this again'
					/>
					<Button autoFocus variant='contained' onClick={handleUpload}>
						Upload data
					</Button>
				</>
			}
		/>
	);
}
