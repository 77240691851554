import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography, Box } from '@mui/material';
import { swish, SectionTitle, SectionHead, _theme } from './library';
import Demo from '../assets/1Demo.png';
import Upload from '../assets/2Upload.png';
import Forecast from '../assets/3Forecast.png';

export default function Working() {
	const Item = ({ icon, title, children }) => (
		<Grid2 xs={12} sm={4}>
			<Box
				sx={{
					width: '100%',
					p: 1,
					margin: '0 auto',
					textAlign: 'center',
					maxWidth: 300,
					img: {
						margin: '0 auto',
						display: 'block',
						width: '100%',
						maxWidth: 225,
					},
				}}>
				<img src={icon} alt='icon' />
				<Typography variant='h5' sx={{ pt: 4, pb: 2 }}>
					{title}
				</Typography>
				<Typography sx={{ color: 'text.secondary' }}>{children}</Typography>
			</Box>
		</Grid2>
	);

	return (
		<Grid2
			container
			direction='row'
			justifyContent='space-between'
			alignItems='flex-start'
			columnSpacing={{ xs: 0, sm: 5 }}
			rowSpacing={{ xs: 4, sm: 0 }}
			sx={{ pt: 4 }}>
			<Grid2 xs={12} sx={{ pb: 3 }}>
				<SectionTitle>working together</SectionTitle>
				<SectionHead>
					Getting <span style={{ ...swish(85) }}>started</span>
					<br />
					is easy...
				</SectionHead>
			</Grid2>

			<Item icon={Demo} title='1. Schedule a demo'>
				Let us show you how our demand forecasting solution can help your
				business.
			</Item>
			<Item icon={Upload} title='2. Provide your data'>
				Give us a sample of your historical data and we will generate a 
				proof-of-concept forecast.
			</Item>
			<Item icon={Forecast} title='3. Get a sample forecast'>
				Evaluate BetterLogiq's forecast against your current process to get a glimpse 
				into the power of our solution.
			</Item>
		</Grid2>
	);
}
