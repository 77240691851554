import * as React from 'react';
import { Box } from '@mui/material';
import Hero from './components/Hero';
import Product from './components/Product';
import Why from './components/Why';
import How from './components/How';
import Working from './components/Working';
import Questions from './components/Questions';
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';
import { _theme } from './components/library';

export default function Home() {
	return (
		<>
			<Hero />
			<Box 
				sx={{
					p: _theme().spacing(1, 8),
					[_theme().breakpoints.down('sm')]: {
						p: _theme().spacing(1, 4),
					},
					[_theme().breakpoints.up('xl')]: {
						p: _theme().spacing(1, 24),
					},
				}}>
				<Product />
				<Why />
				{/* <How /> */}
				<Working />
				<Questions />
			</Box>
			<ContactUs />
			<Footer />
		</>
	);
}
