import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box, Typography, alpha } from '@mui/material';
import logo from '../../../assets/logo.svg';
import logo_text from '../../../assets/logo_text.svg';
import { fullTop } from '../../../Util';
import HeroImg from '../assets/Hero.png';
import HeroBg from '../assets/HeroBg.svg';
import { swish, _theme, PinkButton } from './library';

export default function Hero() {
	return (
		<Grid2
			container
			direction='row'
			justifyContent='space-evenly'
			alignItems='center'
			sx={{
				// background: `url(${HeroBg}) 50% 0 / cover no-repeat`,
				bgcolor: alpha('#BFB1C1', .2),
				pl: 4,
				pr: 4,
				mb: 4,
			}}>
			<Grid2
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
				sx={{
					...fullTop,
					padding: _theme().spacing(1, 4),
					borderBottom: 2,
					borderColor: 'neutral.300',
				}}>
				<Grid2 xs='auto' sx={{ pt: 1 }}>
					<img
						src={logo}
						alt='Betterlogiq logo'
						width={40}
						style={{ marginRight: 10 }}
					/>
					<img
						src={logo_text}
						alt='Betterlogiq logo'
						width={180}
						style={{ verticalAlign: 'top' }}
					/>
				</Grid2>
			</Grid2>

			<Grid2 xs={12}>
				<Typography
					variant='h2'
					sx={{
						maxWidth: 700,
						m: _theme().spacing(4, 'auto', 5),
						textAlign: 'center',
						fontWeight: 800,
						lineHeight: 1,
					}}>
					Demand forecasting that <span style={{ ...swish(90) }}>elevates</span> the bottom line
				</Typography>
				<Typography
					variant='h5'
					sx={{
						maxWidth: 600,
						m: _theme().spacing(0, 'auto'),
						textAlign: 'center',
					}}>
					Anticipate your customers’ needs by making data-backed decisions with
					quantifiable results from BetterLogiq.
				</Typography>
				{/* <Box
					sx={{
						maxWidth: 600,
						m: _theme().spacing(5, 'auto'),
						border: '1px solid rgba(3, 54, 107, 0.25)',
						boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
						borderRadius: 3,
						backgroundColor: 'white',
					}}>
					<input
						type='text'
						placeholder='Enter your email'
						name='email'
						style={{
							border: 'none',
							background: 'none',
							fontSize: '1.25rem',
							margin: '1rem',
						}}></input>
					<PinkButton>Schedule a Demo</PinkButton>
				</Box> */}
			</Grid2>
			<Grid2 xs={12}>
				<Box
					sx={{
						maxWidth: 1500,
						m: _theme().spacing(2, 'auto'),
						p: 2,
					}}>
					<img src={HeroImg} width='100%' alt='Application screen' />
				</Box>
			</Grid2>
		</Grid2>
	);
}
