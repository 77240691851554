import * as React from 'react';
import {
	BarChart,
	Bar,
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
} from 'recharts';
import dayjs from 'dayjs';
import { theme } from '../../App';

function Chart({ view, data, children }) {
	const palette = theme.palette.chart;
	const [size, setSize] = React.useState({ width: 500, height: 350 });
	const box = React.useRef(null);

	React.useEffect(() => {
		setSize({
			width: box.current.offsetWidth,
			height: box.current.offsetHeight,
		});
	}, []);

	return (
		<div width='100%' height='100%' ref={box}>
			{(view === 'Bar' || view === 'Stacked') && (
				<BarChart data={data} width={size.width} height={350}>
					{children}
					{data[0].categories.map((category, idx) => (
						<Bar
							key={`bar-${idx}`}
							stackId='stack'
							name={category}
							dataKey={`values[${idx}]`}
							barSize={5}
							fill={palette[idx]}
						/>
					))}
				</BarChart>
			)}
			{view === 'Line' && (
				<LineChart data={data} width={size.width} height={350}>
					{children}
					{data[0].categories.map((category, idx) => (
						<Line
							key={`line-${idx}`}
							connectNulls
							name={category}
							dataKey={`values[${idx}]`}
							stroke={palette[idx]}
						/>
					))}
				</LineChart>
			)}
		</div>
	);
}

function TimeSeries({ settings, data }) {
	const monthTickFormatter = (date) => {
		return dayjs(date).format('MMM');
	};
	const yearTickFormatter = (date) => {
		if (date === data[0].date || dayjs(date).month() === 0) {
			return dayjs(date).format('YYYY');
		}
		return '';
	};

	return (
		<Chart view={settings.view || 'Line'} data={data}>
			<XAxis
				dataKey='date'
				tickFormatter={monthTickFormatter}
				axisLine={false}
				tickLine={false}
				height={20}
				padding={{ left: 10, right: 10 }}
			/>
			<XAxis
				dataKey='date'
				tickFormatter={yearTickFormatter}
				axisLine={false}
				tickLine={false}
				xAxisId='year'
				padding={{ left: 15, right: 10 }}
			/>
			<YAxis
				orientation='right'
				label={{
					value: settings.label,
					position: 'insideTopRight',
					angle: 90,
					dy: 50,
					dx: 10,
				}}
			/>
			<CartesianGrid strokeDasharray='3 3' />
			<Tooltip />
		</Chart>
	);
}

export default TimeSeries;
