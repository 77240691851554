import { Box } from '@mui/material';
import File2 from '../assets/File2.svg';

export default function FileNumIcon({ size = 48, number = 1, sx }) {
	return (
		<Box display='inline-block' position='relative' height={size} sx={sx}>
			<img src={File2} height={size} alt='' />
			<Box
				bgcolor='primary.main'
				borderRadius={3}
				color='white'
				width='1.75rem'
				textAlign='center'
				height='1.75rem'
				lineHeight={1.7}
				position='relative'
				display='inline-block'
				bottom={2}
				left={-14}>
				{number}
			</Box>
		</Box>
	);
}
