import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid2 from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';

import { theme } from '../../../App';
import { AppContext } from '../../../App';
import Chart from '../assets/Chart.svg';
import Process from '../assets/Process.svg';
import Negative from '../assets/Negative.svg';
import { rndInt } from '../../../Util';
import { BLDataGrid } from '../../../components/BLDataGrid';
import BLDialog from '../../../components/BLDialog';
import { PAGES, FILESTAB } from '../../../Util';
import ChipGo from '../../../components/ChipGo';

import { getRawFilesAPI, createProjectAPI, getProjects, createRunAPI, getRunsAPI } from '../../../api/API';

const StatusChip = ({ Icon, svg, label, bg, time, run, goFiles }) => {
	const handleFiles = () => {
		goFiles(run);
	};
	return (
		<>
			<ChipGo {...{ Icon, svg, label, bg }} onClick={handleFiles} />
			{time && (
				<Typography variant='overline' color='neutral.700'>
					est {time.diff(dayjs(), 'm')}min
				</Typography>
			)}
		</>
	);
};

const Create = ({ instance, open, setOpen, handleSave }) => (
	<BLDialog
		open={open}
		onClose={() => setOpen(false)}
		title={`Create a new ${instance}`}
		fullWidth
		maxWidth='xs'
		content={
			<>
				<TextField
					id='new-name'
					label='Name'
					variant='filled'
					required
					autoFocus
					fullWidth
					sx={{ display: 'block', mb: 2 }}
				/>
				<TextField id='new-description' label='Description' variant='filled' fullWidth />
			</>
		}
		actions={
			<>
				<Button variant='outlined' onClick={() => setOpen(false)}>
					Cancel
				</Button>
				<Button variant='contained' onClick={handleSave}>
					Save
				</Button>
			</>
		}
	/>
);

const Delete = ({ obj, open, setOpen, doDelete }) => {
	const [confirmDelete, setConfirmDelete] = React.useState(false);

	const changeDelete = (e) => {
		if (e.target.value === 'DELETE') {
			setConfirmDelete(true);
		} else {
			setConfirmDelete(false);
		}
	};

	return (
		<BLDialog
			open={open}
			onClose={() => setOpen(false)}
			title={`Do you want to delete <${obj.name}> and all its data?`}
			content={
				<>
					<Typography>
						Type <strong>DELETE</strong> to proceed. This action can’t be
						undone.
					</Typography>
					<br />
					<TextField
						onChange={changeDelete}
						variant='filled'
						label='DELETE'
						name='delete'
						placeholder='DELETE'
					/>
				</>
			}
			color='error.main'
			actions={
				<>
					<Button
						variant='outlined'
						onClick={() => {
							setOpen(false);
							setConfirmDelete(false);
						}}>
						Cancel
					</Button>
					<Button
						variant='contained'
						disabled={!confirmDelete}
						onClick={() => {
							doDelete(obj);
							setOpen(false);
							setConfirmDelete(false);
						}}
						color='error'>
						Delete
					</Button>
				</>
			}
		/>
	);
};

const getStatusChip = (run, goFiles) => {
	if (run.status === 'awaiting_data') {
		return (
			<StatusChip
				svg={Negative}
				label='Awaiting Data'
				bg='neutral.300'
				{...{ run, goFiles }}
			/>
		);
	}else if (run.status === 'model_complete') {
		return (
			<StatusChip
				svg={Chart}
				label='Model complete'
				bg='success.light'
				{...{ run, goFiles }}
			/>
		);
	} else if (run.status === 'model_processing') {
		return (
			<StatusChip
				svg={Process}
				label='Processing model'
				bg='#D1E7FA'
				{...{ run, goFiles }}
				// time={run.processingEst}
			/>
		);
	} else if (run.status === 'preprocessing' || run.status === 'premodel') {
		return (
			<StatusChip
				svg={Process}
				label='Pre-model'
				bg='#D1E7FA'
				{...{ run, goFiles }}
			/>
		);
	} else
		return (
			<StatusChip
				svg={Negative}
				label='Incomplete'
				bg='neutral.300'
				{...{ run, goFiles }}
			/>
		);
};

// make sure project list is assigned. need to find out how to wait for this list to populate before moving on. 
// const test = getProjects();

export default function Table() {
	const appContext = React.useContext(AppContext);
	const [project, setProject] = React.useState('');
	const [newProject, setNewProject] = React.useState(false);
	const [deleteProject, setDeleteProject] = React.useState(false);
	const [runs, setRuns] = React.useState(null);
	const [newRun, setNewRun] = React.useState(false);
	const [pageSize, setPageSize] = React.useState(10);
	const [deleteRun, setDeleteRun] = React.useState(false);

	// get the list of projects in the app
	const projectsApi = JSON.parse(localStorage.getItem('projectList'));
	// set the runs list in local storage
	localStorage.setItem('currentRuns', JSON.stringify(runs));
	// set the project list in local storage
	

	const runColumns = [
		{
			field: 'i',
			headerName: '',
			headerClassName: 'idx',
			width: 30,
			filterable: false,
			sortable: false,
			disableColumnMenu: true,
			disableReorder: true,
			renderCell: (data) => data.api.getRowIndex(data.row.id) + 1,
		},
		{
			field: 'name',
			headerName: 'Run Name',
			cellClassName: 'runName',
			width: 200,
			editable: true,
			hideable: false,
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 250,
			renderCell: (data) => getStatusChip(data.row, goFiles),
		},
		{
			field: 'delete',
			headerName: '',
			width: 40,
			filterable: false,
			sortable: false,
			disableColumnMenu: true,
			disableReorder: true,
			hideable: false,
			renderCell: (data) => (
				<Button
					title={`Delete ${data.row.name}`}
					onClick={() => setDeleteRun({ id: data.row.id, name: data.row.name })}
					sx={{ minWidth: 0, color: 'error.main' }}>
					<DeleteOutlined />
				</Button>
			),
		},
		{
			field: 'created',
			headerName: 'Created on',
			width: 170,
			renderCell: (data) => data.row.created.substring(0, 10),
			// valueFormatter: (params) => params.value.toDate().toDateString(),
		},
		{
			field: 'description',
			headerName: 'Description',
			width: 200,
			hide: true,
		},
		{
			field: 'tags',
			headerName: 'Tags',
			width: 150,
			hide: true,
		},
	];

	const handleProjectSelect = (e) => {
		if (e.target.value < 0) {
			setNewProject(true);
			return;
		}
		localStorage.setItem('currentProject', JSON.stringify(e.target.value));
		setProject(e.target.value);
		
		getRunsAPI().then((res) => {
			var filteredRunList =  res.data.filter(function(entList) {
				return entList.project === e.target.value.id;
			});
			setRuns(filteredRunList);
			console.log('setRuns in getRunsAPI in Runs/Table')
			localStorage.setItem('currentRuns', JSON.stringify(filteredRunList));
		}).catch((error) => {
			console.error(error);
		});
	};

	const handleNewProject = (e) => {
		createProjectAPI(document.getElementById('new-name').value, document.getElementById('new-description').value)
		.then((newProject) => {
			console.log(newProject.data);
			getProjects()
			.then((results) => {
				localStorage.setItem('projectList', JSON.stringify(results.data))
				console.log('getProjects called from handleNewProject in Runs/Table')
			}).catch((excep) => console.error(excep));
			console.log('getProjects called from handleNewProject in Runs/Table')
			setNewProject(false);
			handleProjectSelect({ target: { value: newProject.data } });
		})
		.catch((excep) => {
			console.error(excep)
		});
	};

	const handleNewRun = (e) => {
		createRunAPI(document.getElementById('new-name').value, document.getElementById('new-description').value, project.id)
		.then((newRun) => {
			console.log(newRun.data);
			getProjects().then((results) => {
				localStorage.setItem('projectList', JSON.stringify(results.data))
				console.log('getProjects called from handleNewRun in Runs/Table')
			}).catch((excep) => console.error(excep));
			console.log('getProjects called from handleNewRun in Runs/Table')
			const runsCopy = [...runs];
			runsCopy.push(newRun.data);
			setRuns(runsCopy);
			setNewRun(false);
			appContext.updateStore({
				page: PAGES.FILES,
				filesTab: FILESTAB.UPLOAD,
				project: { ...project },
				run: { ...newRun.data },
				guideShown: false,
			});
		}).catch((excep) => console.error(excep));
	};

	const goFiles = (run) => {
		let tab = FILESTAB.UPLOAD;

		if (run.status === 'preprocessing') tab = FILESTAB.PRE;
		if (run.status === 'premodel') tab = FILESTAB.PRE;
		if (run.status === 'model_processing') tab = FILESTAB.PRE;
		if (run.status === 'model_complete') tab = FILESTAB.POST;
		getRawFilesAPI().then((res) => {
			localStorage.setItem('rawDataFiles', JSON.stringify(res.data))
			if (res.data != []) {
				appContext.updateStore({
					page: PAGES.FILES,
					filesTab: tab,
					filesSubtab: FILESTAB.FILES,
					project: { ...project },
					run: run,
					guideShown: true,
				});
			};
			});

		appContext.updateStore({
				page: PAGES.FILES,
				filesTab: tab,
				filesSubtab: FILESTAB.FILES,
				project: { ...project },
				run: run,
				guideShown: true,
		});
	};

	const handleRename = (params, evt, details) => {
		//setProject(params, evt, details);
	};

	React.useEffect(() => {
		if (appContext.store.project)
			handleProjectSelect({ target: { value: appContext.store.project } });
	}, [appContext.store.project]);

	return (
		<>
			<Grid2
				container
				justifyContent='space-between'
				sx={{ p: theme.spacing(3, 4) }}>
				<Grid2 xs={6} sx={{}}>
					<Typography
						variant='h4'
						color='primary.main'
						sx={{ display: 'inline-block', mr: 6, mt: 0.25 }}>
						Home
					</Typography>
					<FormControl variant='standard' sx={{ minWidth: 180, mr: 2 }}>
						<Select
							labelId='project-label'
							id='project-select'
							value={project}
							label='Project'
							displayEmpty
							renderValue={(selected) => {
								if (!selected) {
									return <em>Select a project</em>;
								} else return project.name;
							}}
							onChange={handleProjectSelect}>
							<MenuItem value={-1}>
								<Typography color='primary.main'> + New Project </Typography>
							</MenuItem>
							{projectsApi.map((p, i) => (
								<MenuItem value={p} key={p.id}>
									{p.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{project && (
						<Button
							disabled={!project}
							title={`Delete ${project.name}`}
							onClick={() =>
								setDeleteProject({ id: project.id, name: project.name })
							}
							sx={{ minWidth: 0, verticalAlign: 'top', color: 'error.main' }}>
							<DeleteOutlined />
						</Button>
					)}
				</Grid2>
				<Grid2 xs={6} sx={{ textAlign: 'right', mb: 4 }}>
					<Button
						variant='contained'
						disabled={project === ''}
						onClick={() => setNewRun(true)}
						sx={{ ml: 2 }}>
						New Run
					</Button>
				</Grid2>
				<Grid2 xs={12}>
					{runs && (
						<BLDataGrid
							rows={runs}
							columns={runColumns}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							rowsPerPageOptions={[10, 20, 50]}
							density='compact'
							rowHeight={58}
							onCellEditCommit={handleRename}
							disableSelectionOnClick
							autoHeight
						/>
					)}
				</Grid2>
			</Grid2>
			{runs && (
				<Delete
					obj={deleteRun}
					open={!!deleteRun}
					setOpen={setDeleteRun}
					doDelete={(obj) => setRuns(runs.filter((r) => r.id !== obj.id))}
				/>
			)}
			{project && (
				<Delete
					obj={deleteProject}
					open={!!deleteProject}
					setOpen={setDeleteProject}
					doDelete={(obj) => {
						projectsApi = projectsApi.filter((p) => p.id !== obj.id);
						setProject('');
						setRuns('');
					}}
				/>
			)}
			{newProject && (
				<Create
					instance={'project'}
					open={newProject}
					setOpen={setNewProject}
					handleSave={handleNewProject}
				/>
			)}
			{newRun && (
				<Create
					instance={'run'}
					open={newRun}
					setOpen={setNewRun}
					handleSave={handleNewRun}
				/>
			)}
			{/* <UploadGuide open={newRun} doClose={() => setNewRun(false)} /> */}
		</>
	);
}
