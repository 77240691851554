import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { swish, SectionTitle, SectionHead } from './library';
import Forecasts from '../assets/product/Forecasts.svg';
import Dashboard from '../assets/product/Dashboard.svg';
import SmartModel from '../assets/product/SmartModel.svg';
import Folder from '../assets/product/Folder.svg';
import Money from '../assets/product/Money.svg';
import Support from '../assets/product/Support.svg';

export default function Product() {
	const Item = ({ icon, title, children }) => (
		<Grid2 xs={6} md={4}>
			<img src={icon} alt='icon' width={45} />
			<Typography
				variant='h5'
				sx={{
					pt: .5,
					pb: 1,
				}}>
				{title}
			</Typography>
			<Typography>{children}</Typography>
		</Grid2>
	);

	return (
		<Grid2
			container
			direction='row'
			justifyContent='space-between'
			alignItems='flex-start'
			spacing={4}
			columnSpacing={{ xs: 1.5, sm: 6 }}>
			<Grid2 xs={12}>
				<SectionTitle>Product highlights</SectionTitle>
				<SectionHead>
					Extract the <span style={{ ...swish(95) }}>hidden&nbsp;value</span> in
					your sales data
				</SectionHead>
			</Grid2>
			<Item icon={Forecasts} title='AI-driven forecasts'>
				Benefit from best-in-class machine learning to select and optimize
				models.
			</Item>
			<Item icon={Dashboard} title='Intuitive dashboard'>
				Straightforward dashboards offer easy-to-digest insights. No training
				required.
			</Item>
			<Item icon={SmartModel} title='Smart model inputs'>
				Super fast and integrated data validation to help avoid forecast
				pitfalls.
			</Item>
			<Item icon={Folder} title='Flexible targets'>
				Model different time horizons aligned to business decisions.
			</Item>
			<Item icon={Support} title='Live support'>
				Not just technical support. Get help from our expert data scientists.
			</Item>
			<Item icon={Money} title='Intelligent overlays'>
				Based on decades of real-world data science expertise.
			</Item>
		</Grid2>
	);
}
