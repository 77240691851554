import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SignPass from './SignPass';
import Factor2 from './Factor2';
import FactorCode from './FactorCode';
import Fail from './Fail';
import Reset from './Reset';

import { theme } from '../../../App';
import logo from '../../../assets/logo.svg';
import logo_text from '../../../assets/logo_text.svg';

import { AppContext } from '../../../App';
import { PAGES } from '../../../Util';

import { useAuth0 } from "@auth0/auth0-react";

export const STEP = {
	SIGNIN: 'signin',
	FACTOR2: 'factor2',
	FACTOR_CODE: 'factor_code',
	FAIL: 'fail',
	RESET: 'reset',
	RESET_FORM: 'reset_form',
}

export default function SignWindow() {
	const appContext = React.useContext(AppContext);
	const auth0 = useAuth0();
	const [title, setTitle] = React.useState('');
	// const [user, setUser] = React.useState({ phone: null, email: null, name: null, avatar: null })

	return (
		<Box sx={{
			width: '90%',
			maxWidth: 500,
			padding: 4,
			m: theme.spacing(3, 'auto'),
			boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.15)',
			boxSizing: 'border-box',
			borderRadius: 2,
			backgroundColor: 'white',
		}}>
			<Box sx={{
				textAlign: 'center',
				width: '100%',
			}}>
				<img src={logo} alt='Betterlogiq logo' style={{
					width: 60
				}} /><br />
				<img src={logo_text} alt='Betterlogiq logo' style={{
					width: 240
				}} />
			</Box>
			<Typography variant="h3" gutterBottom sx={{
				color: 'primary.main',
				mt: 5,
			}}>
				{title}
			</Typography>
			<SignPass/>
		</Box>
	)
}
