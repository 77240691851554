import axios from "axios";

// comment to force frontend redeploy
// verify authentication... not working at the moment
export const checkAuthentication = () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    const body = JSON.stringify({ token: localStorage.getItem('access') });
    const instance = axios.create();
    return instance.post(
        '/auth/jwt/verify/',
        body,
        config
    );
};

// get projects list
export const getProjects = () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };
    const instance = axios.create();
    return instance.get(
        '/project/',
        config
    );
};

// create new project api
export const createProjectAPI = (name, description) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };
    const body = JSON.stringify({
        name: name,
        description: description
    });
    const instance = axios.create();
    return instance.post(
        '/project/',
        body,
        config
    );
};

// create new run api
export const createRunAPI = (name, description, projectID) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };
    const body = JSON.stringify({
        name: name,
        description: description,
        project: projectID
    });
    const instance = axios.create();
    return instance.post(
        '/run/',
        body,
        config
    );
};

// get Runs list
export const getRunsAPI = () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };
    const instance = axios.create();
    return instance.get(
        '/run/',
        config
    );
};

// get single Run
export const getSingleRunAPI = (runID) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };
    const instance = axios.create();
    return instance.get(
        `/run/${runID}/`,
        config
    );
};

export const directUploadStart = ( runID, fileName, dataType ) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };
    localStorage.setItem('uploadStartRunID', runID)
    const body = {
        run: runID,
        name: fileName,
        data_type: dataType
    };
    const instance = axios.create();
    return instance.post(
        '/rawdata/delayed_create/',
        body,
        config
    );
};

export const directUploadDo = ( data, file ) => {
    var postData = new FormData();

    for (const key in data.fields) {
        postData.append(key, data.fields[key]);
    }
    postData.append('file', file);

    let postParams = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    // If we're uploading to S3, detach the authorization cookie.
    // Otherwise, we'll get CORS error from S3
    if (data?.fields) {
        postParams = {};
    };
    const instance = axios.create();
    return instance.post(`${data.url}`, postData, postParams).then(() => Promise.resolve({ fileId: data.id }));
};

export const directUploadFinish = () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };
    localStorage.setItem('uploadFinishHeaders', JSON.stringify(config));
    const runID = localStorage.getItem('uploadStartRunID');
    const instance = axios.create();
    return instance.post(
        `/rawdata/${runID}/finish/`,
        '',
        config
    );
};

// get raw files from backend
export const getRawFilesAPI = () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };
    const instance = axios.create();
    return instance.get(
        '/rawdata/',
        config
    );
};

// get pre model visualization Data
export const getPreModelVisData = (runID) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };
    const instance = axios.create();
    return instance.get(
        `/run/${runID}/time_series_preprocess_vis/`,
        config
    );
};

// get pre model visualization Data
export const getPostModelVisData = (runID) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };
    const instance = axios.create();
    return instance.get(
        `/run/${runID}/time_series_post_model_vis/`,
        config
    );
};