import * as React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Avatar, Typography, Tabs, Tab, Box } from '@mui/material';

import logo from '../../../assets/logo.svg';
import { fullTop } from '../../../Util';
import { AppContext, theme } from '../../../App';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export default function Header({ tab, setTab }) {
	const appContext = React.useContext(AppContext);
	
	// adding appContext to local storage for dev and debugging
	localStorage.setItem('appContext', JSON.stringify(appContext));
	
	const handleTab = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<Grid2
			container
			direction='row'
			alignItems='stretch'
			sx={{
				...fullTop,
				padding: theme.spacing(2, 4, 0),
				bgcolor: 'neutral.100',
				borderBottom: 2,
				borderColor: 'neutral.300',
			}}>
			<Grid2 xs={1}>
				<img src={logo} alt='Betterlogiq logo' width={40} />
			</Grid2>
			<Grid2 xs={6}>
				<Typography
					variant='h6'
					fontWeight={700}
					color='primary'
					pt={1}
					pl={0.5}>
					{appContext.store.run.name}
				</Typography>
				<Tabs value={tab} onChange={handleTab}>
					<Tab label='Home' />
					<Tab label='Data Files' />
					<Tab label='Pre-model' disabled={(appContext.store.run.status === 'awaiting_data')} />
					<Tab label='Post-model' disabled={(appContext.store.run.status === 'awaiting_data' || appContext.store.run.status === 'preprocessing' || appContext.store.run.status === 'premodel')} />
				</Tabs>
			</Grid2>
			<Grid2 xs={5} textAlign='right'>
				<Box
					id='process-btns'
					sx={{
						display: 'inline-block',
						mr: 2,
						mt: 0.5,
						verticalAlign: 'top',
					}}></Box>
				<Avatar
					alt={appContext.store.user.name}
					src={appContext.store.user.avatar}
					sx={{
						width: 40,
						height: 40,
						display: 'inline-block',
					}}></Avatar>
			</Grid2>
		</Grid2>
	);
}
